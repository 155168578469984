<template>
    <DialogNeo dialog-title="Confirm" :show="show" dialog-width="25%" dialog-height="unset">
      <template #content>
        <v-container>
          <v-row align="center">
            <v-col>
              <p>{{ message }}</p>
            </v-col>
          </v-row>
          <v-row align="end" justify="center">
            <v-col align="right">
              <v-btn color="error" @click="$emit('cancel')">
                Cancel
              </v-btn>
              <v-btn color="primary" style="margin-left: 15px;" @click="$emit('confirm')">
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template #dialog-header-right>
        <v-btn icon="mdi-close-thick" size="small" variant="text" @click="$emit('cancel')"></v-btn>
      </template>
    </DialogNeo>
  </template>
  
  <script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: true
      },
      message: {
        type: String,
        required: true
      }
    },
    emits: ['cancel', 'confirm'],
  };
  </script>
  
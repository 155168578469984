//IMPORTS
import 'vuetify/styles'; // Global CSS has to be imported
import { createVuetify } from 'vuetify';
import App from './DesktopView.vue';
import { createApp, ref } from 'vue';
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi';
import '@mdi/font/css/materialdesignicons.css';
import { loadFonts } from './plugins/webfontloader';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import router from '@/router/desktopRouter';
import axiosInstance from '@/plugins/axios';
import storageManager from '@/plugins/storageManager';
import { createPinia } from 'pinia';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import dialogWindow from '@/components/ui/DialogWindow';
import appNotifications from '@/components/AppNotifications';
import matchedReports from '@/components/MatchedReports';
import confirmDialog from '@/components/ConfirmDialog';
import appMessageNotifications from '@/components/AppMessageNotifications';
import spinnerComponent from '@/components/SpinnerComponent';
import dataLoading from '@/components/ui/DataLoading';
import noData from '@/components/ui/NoData';
import dataLoadingError from '@/components/ui/DataLoadingError';
import Vue3TouchEvents from "vue3-touch-events";
import Acl from '@/plugins/acl';
import VueClick from 'vue-click';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSame from 'dayjs/plugin/isSameOrBefore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";


//CONSTANTS
const theme = ref('light')
const pinia = createPinia()
const vuetify = createVuetify({
    components,
    directives,
    toggleTheme: () => theme.value = theme.value === 'light' ? 'dark' : 'light',
    icons: {
        defaultSet: 'mdi',
        sets: {
            mdi,
        },
        aliases: {
            ...aliases,
            home: 'mdi-home',
            account: 'mdi-account'
        }
    },
    theme: {
        defaultTheme: 'compassTheme',
        themes: {
            compassTheme: {
                dark: false,
                colors: {
                    background: '#FFFFFF',
                    surface: '#FFFFFF',
                    primary: '#4E6F8E',
                    'primary-darken-1': '#3700B3',
                    secondary: '#DBDED9',
                    'secondary-darken-1': '#018786',
                    error: '#ff0000',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FB8C00'
                }
            }
        }
    }
})


//CODE
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSame);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault('UTC');

loadFonts();
let app = createApp(App);
app.provide('axios', axiosInstance);
app.provide('storageManager', new storageManager);
app.provide('theme', 'compassTheme');
app.provide('dayJs', dayjs);
app.component('DatePicker', Datepicker);
//app.component('AxiosLink', axios);
app.component('DialogNeo', dialogWindow);
app.component('DataLoading', dataLoading);
app.component('NoData', noData);
app.component('DataLoadingError', dataLoadingError);
app.component('AppNotifications', appNotifications);
app.component('AppMessageNotifications', appMessageNotifications);
app.component('SpinnerComponent', spinnerComponent);
app.component('MatchedReports', matchedReports);
app.component('ConfirmDialog', confirmDialog);
app.use(vuetify);
//app.use(VueAxios, axios);
app.use(pinia);
//app.use(dialogPlugin);
app.use(Vue3TouchEvents, {
    swipeTolerance: 100
});
app.use(VueClick);
app.use(router);
app.use(
    VueTippy,
    {
        defaultProps: {
            placement: 'auto',
            allowHTML: true,
            arrow: true,
            arrow_type: 'sharp',
            delay: 500,
            duration: 0,
            animation: 'none'
        }
    }
);

import { useAppStore } from '@/stores/app'
const store = useAppStore()


//SENTRY
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        app,
        dsn: "https://dbb3bd3f836d43cc8e6c9a5767522b38@sentry.survey-compass.com/14",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "my-site-url.com", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

global.config = {};
global.config.the_flow_url = process.env.VUE_APP_THE_FLOW_URL;
//global.config.workflow_url = process.env.VUE_APP_WORKFLOW_URL;        //This is set when the login information is retrieved from Laravel
axiosInstance.defaults.baseURL = global.config.the_flow_url;
app.use(Acl, store.user);
store.user.app = 'desktop';
app.mount('#app');
